import React from "react";
import { graphql, Link, PageProps } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import { Card, CardMedia, makeStyles, Typography } from "@material-ui/core";
import SEO from "../components/SEO";
import Layout from "../components/Layout";

const useStyles = makeStyles(theme => ({
  hero: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
  article: {
    ...theme.typography.body2,
    padding: theme.spacing(3, 0),
  },
  link: {
    textDecoration: 'none',
  },
}));

const Index: React.FC<PageProps<GatsbyTypes.IndexQuery>> = (props) => {
  const classes = useStyles();
  const { data } = props;

  return (
    <Layout>
      <SEO />

      <Card className={classes.hero}>
        <CardMedia>
          <GatsbyImage image={data.hero?.childImageSharp?.gatsbyImageData!} alt=""/>
        </CardMedia>
      </Card>

      {data.allMarkdownRemark.edges.map(({ node }) => (
        <article className={classes.article} key={node.id}>
          <Typography variant="subtitle1">
            {node.frontmatter?.date}
          </Typography>
          <Link to={node.frontmatter?.path!} className={classes.link}>
            <Typography variant="h5" gutterBottom>
              {node.frontmatter?.title}
            </Typography>
          </Link>
          {/* <Typography variant="body1">
            {node.excerpt}
          </Typography> */}
        </article>
      ))}

    </Layout>
  );
};

export default Index;

export const query = graphql`
  query Index {
    site {
      siteMetadata {
        title
      }
    }
    hero: file(sourceInstanceName: {eq: "images"}, relativePath: {eq: "hero.png"}) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    allMarkdownRemark(
      filter: {frontmatter: {status: {eq: "published"}}},
      sort: { fields: [frontmatter___date], order: DESC },
      limit: 10
    ) {
      edges {
        node {
          id
          excerpt(pruneLength: 100)
          frontmatter {
            title
            date(formatString: "YYYY/MM/DD")
            path
          }
        }
      }
    }
  }
`;
